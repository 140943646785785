import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { SelectedPage } from "../../shared/type.js";
import pace4 from '../../assets/pace4.jpg';
import pace5 from '../../assets/pace5.jpg';
import pace2 from '../../assets/pace2.jpg';
import paceE from '../../assets/paceE.jpg';
import paceD from '../../assets/paceD.png';
import Htext from '../../shared/Htext.js';
import Class from './Class';

const classes = [
  {
    name: 'Technology Integration',
    description: 'This included the implementation of automated systems for documentation, tracking, and compliance, aimed atreducing errors and enhancing efficiency in the clearing process.',
    image: pace4,
  },
  {
    name: 'Trade Regulations and Brexit Impact',
    description: 'Changes in trade agreements, tariffs,and customs regulations due to events like Brexit were significantly affecting the industry. Firms were navigating new compliance requirements and adapting their strategies to accommodate shifting trade dynamics.',
    image: pace5,
  },
  {
    name: 'Supply Chain Disruptions',
    description: 'Disruptions: Global supply chain disruptions, exacerbated by the COVID-19 pandemic, were impacting the industry. Clearing firms were working on innovative solutions to mitigate delays, shortages, and logistical challenges caused by disruptions in transportation and manufacturing.',
    image: pace2,
  },
  {
    name: 'Sustainability Focus',
    description: 'There was an increasing emphasis on sustainable practices within the industry, with firms exploring eco-friendly logistics solutions, such as optimizing transportation routes to minimize carbon footprint and adopting greener packaging materials.',
    image: pace4,
  },
  {
    name: 'Evolving Customer Expectations',
    description: 'Clients were seeking more comprehensive services beyond traditional clearing and forwarding. They were looking for integrated solutions, including supply chain consultancy, risk management, and advanced technology-driven services to enhance their global logistics operations',
    image: paceE,
  },
  {
    name: 'Technology Integration',
    description: 'Embracing cutting-edge technology, our firm streamlines processes, employs automation, and ensures real-time tracking for all shipments.',
    image: paceD,
  },
];

const OurClasses = ({ setSelectedPage }) => {


    return (
      <section id="whychooseus" className="w-full py-10 bg-primary-100">
        <motion.div onViewportEnter={() => setSelectedPage(SelectedPage.Whyus)}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delayq: 0.2, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: 50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="text-center text-white">
              <Htext>Why Us</Htext>
              <p>
                Through our unwavering commitment to excellence, we aim to be the trusted backbone that simplifies logistics, amplifies growth opportunities, and ensures unparalleled client satisfaction
              </p>
            </div>
          </motion.div>
          <div className="mt-10 h-[353px] w-full md:overflow-x-scroll overflow-y-hidden">
            <div className="whitespace-nowrap  ">
              {classes.map((item, index) => (
                <Class key={`${item.name}-${index}`} name={item.name} description={item.description} image={item.image} />
              ))}
            </div>
          </div>
        </motion.div>
      </section>
    );
  };
  
  export default OurClasses;