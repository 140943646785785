import Logo from "../../assets/looogo.png";
import { SelectedPage } from "../../shared/type";
import Link from "../../scenes/navbar/Link";
import ActionButton from "../../shared/ActionButton";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { FaWhatsapp } from 'react-icons/fa';

const Footer = ({ selectedPage, setSelectedPage }) => {
  const whatsappStyle = {
    color: 'green',
    fontSize: '24px', // Adjust the size as needed
  };
  return (
    <footer className="bg-primary-100 py-16 text-white">
      <div className="justify-content mx-auto w-5/6 gap-16 md:flex">
        <div className="mt-16 basis-1/4 md:mt-0">
          <h4 className="font-bold">
            <ActionButton setSelectedPage={setSelectedPage}>Contact us</ActionButton>
          </h4>
          <div className="flex flex-col mt-2 items-start p-4 gap-4">
            <div className="flex items-center justify-center gap-3">
              {" "}
              <EnvelopeIcon className="h-6 w-6" /> <p>Paceforwarders@yahoo.com</p>
            </div>
            <div className="flex items-center justify-center gap-3">
            <PhoneIcon className="h-6 w-6" /> <p>0702503458</p>
            </div>
            <div className="flex items-center justify-center gap-3">
              {" "}
              <EnvelopeIcon className="h-6 w-6" /> <p>ahimbisibweisrael2@gmail.com</p>
            </div>
            <div className="flex items-center justify-center gap-3">
            <a href="https://wa.me/256772503458" target="_blank">
      <FaWhatsapp style={whatsappStyle} />
      </a>
        <p>0772503458</p>
      
    </div>
          </div>
        </div>

        <div className="mt-4 basis-1/4 md:mt-0">
          <h4 className="font-bold">Links</h4>
          <p className="my-3">
            <Link page="Home" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </p>
          <p className="my-3">
            <Link page="Services" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </p>
          <p className="my-3">
            <Link page="About Us" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </p>
          <p className="my-3">
            <Link page="Why Choose Us" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </p>
        </div>
        <div className="mt-4 basis-1/2 md:mt-0">
          <AnchorLink onClick={() => setSelectedPage(SelectedPage.ContactUs)} href={`#${SelectedPage.Home}`}>
            <img alt="logo" src={Logo} />
          </AnchorLink>

          <p className="my-5">
            Over the years Pace forwarders has weathered industry shifts, adapting and innovating to meet evolving client demands. Our growth story is a testment to our adaptability, resilience, and passion for delivering exceptional service.
          </p>
          <p>© 2023 Pace Forwarders All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
