export const SelectedPage = {
  Home: "home",
  Services: "services",
  AboutUs: "aboutus",
  Whyus: "whychooseus",
  ContactUs: "contactUs",
};

export const BenefitType = {
  icon: null, // Replace null with the appropriate JSX.Element
  title: "",
  description: "",
  descriptiontwo: "",
  descriptionthree: "",
};

export const ClassesType = {
  name: "",
  description: "",
  image: "",
};
