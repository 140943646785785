import { SelectedPage } from "../../shared/type";
import { motion } from "framer-motion";
import ActionButton from "../../shared/ActionButton";
import pace2 from "../../assets/pace2.jpg";

const Home = ({ setSelectedPage }) => {
  return (
    <section
      id="home"
      style={{ backgroundImage: `url(${pace2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      className="gap-16  py-10 md:h-screen md:pb-0 relative "
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      {/* imageandcontent */}
      <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.Home)}
        className="md:flex  mx-auto w-5/6 items-center  justify-center md:h-5/6 gap-4 "
      >
        {/*main header */}
        <motion.div
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          className="z-10 mt-32 md:basis-3/5 "
        >
          {/* headings */}
          <div className="md:-mt-20 relative">
            <div className="before:absolute before:-top-20 before:-left-20 before:z-[-1] ">
              <h1 className="text-[50px] font-bold  text-white">PACE FORWARDERS <span>LIMITED</span></h1>
              <h3 className="text-slate-50  font-bold mt-1 text-2xl ">Redefining efficiency in clearing and forwarding services by fostering reliable, transparent, and innovative solutions. </h3>
            </div>
          </div>

          <div className="mt-4 mb-8 flex items-center gap-8 text-white font-semibold">
            <ActionButton setSelectedPage={setSelectedPage}>Contact Us</ActionButton>
          </div>
        </motion.div>
        {/* IMAGES */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          className="flex basis-3/5 justify-center mt-6
            md:z-10 md:ml-40 md:mt-5 md:justify-items-end bg-green-500"
        >
          {/* <img src={paceA} alt="HomePageGraphic"/> */}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Home;
