import { motion } from "framer-motion";
import Htext from "../../shared/Htext";
import ActionButton from "../../shared/ActionButton";
import owner from "../../assets/owner.jpg";


const Aboutus = ({ setSelectedPage }) => {
  return (
    <section
      id="aboutus"
      className="flex flex-col mx-auto min-h-full min-w-full w-5/6 py-20 md:mt-15 "
    >
      <Htext>
        <h2 className="text-3xl font-bold text-center">About Us</h2>
      </Htext>

      <div className="mt-16 md:flex items-center bg-primary-100 text-white justify-between gap-20 md:mt-5  rounded-md p-5">
        {/* image */}
        <img
          className="md:w-[500px] md:h-[500px]"
          alt="benefits-page-graphic"
          src={owner}
        />

        {/* description */}
        <div>
          {/* title */}
          <div className="relative">
            <div className="before:absolute before:-top-20 before:-left-20 before:z-[1] ">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                variants={{
                  hidden: { opacity: 0, x: -50 },
                  visible: { opacity: 1, x: 0 },
                }}
              >
                <Htext>
                  Company Background
                  <span className="text-primary-500"></span>
                </Htext>
              </motion.div>
            </div>
          </div>
          {/* description */}
          <motion.div
            className="p-6"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: 50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <p className="my-5">
              Pace Forwarders Limited was found in 2000 by Ahimbisibwe Israel
              with a vision to revolutionize global logistics. What began as a
              modest venture has now evolved into a leading force in the
              clearing and forwarding industry. With a humble beginning focused
              on local trade, our firm rapidly expanded its horizons,
              embracing the challenges and opportunities presented by the
              dynamic world of international commerce.
            </p>
            <p className="my-5">
              Over the years Pace forwarders has weathered industry shifts,
              adapting and innovating to meet evolving client demands. Our
              growth story is a testament to our adaptability, resilience, and
              passion for delivering exceptional service. Today, we stand as a
              beacon of reliability and efficiency in the clearing and
              forwarding sector, catering to a diverse clientele spanning
              various industries.
            </p>
          </motion.div>
          {/* button */}
          <div className="relative mt-5">
            <div className="before:absolute before:-bottom-20 before:right-40 ">
              <ActionButton setSelectedPage={setSelectedPage}>
                Contact Us
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
