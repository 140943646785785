import Htext from "../../shared/Htext";
import { SelectedPage } from "../../shared/type";
import { TruckIcon, DocumentTextIcon, ArchiveBoxIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import Benefit from "./Benefit";

const benefits = [
  {
    icon: <DocumentTextIcon className="w-6 h-6" />,
    title: "Customs Clearance",
    description: "Preparation and submission of necessary customs documentation",
    descriptiontwo: "Ensuring adherence to local and international trade regulations",
    descriptionthree: "Guidance on duties, taxes, and tariffs.",
  },
  {
    icon: <TruckIcon className="h-6 w-6" />,
    title: "Freight Forwarding",
    description: "Arranging transportation via air, sea, road, or rail",
    descriptiontwo: "Planning and optimizing routes for efficient delivery",
    descriptionthree: "Monitoring shipments and providing real-time updates",
  },
  {
    icon: <ArchiveBoxIcon className="h-6 w-6" />,
    title: "Warehousing and Distribution",
    description: "Offering secure storage options for goods",
    descriptiontwo: "Monitoring, organizing, and managing inventory.",
    descriptionthree: "Coordinating the onward movement of goods to their final destination",
  },
];

const container = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.2 },
  },
};

const Benefits = ({ setSelectedPage }) => {
  return (
    <section id="services" className="mx-auto min-h-full  w-5/6 py-20 ">
      <motion.div
        className=""
        onViewportEnter={() => setSelectedPage(SelectedPage.Services)}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          className="md:ml-60 md:my-5  md:w-3/5 text-center"
        >
          <Htext>Services offered </Htext>
          <p className="my-5 text-[16px] font-bold ">
            We aim to streamline the movement of goods, optimize supply chains, and ensure compliance with regulatory standards, thereby facilitating smoother and more efficient international trade for businesses..
          </p>
        </motion.div>

        {/* Benefits */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={container}
          className="md:flex items-center justify-between gap-8 mt-5 "
        >
          {benefits.map((benefit) => (
            <Benefit
              key={benefit.title}
              icon={benefit.icon}
              title={benefit.title}
              description={benefit.description}
              descriptiontwo={benefit.descriptiontwo}
              descriptionthree={benefit.descriptionthree}
              setSelectedPage={setSelectedPage}
            />
          ))}
        </motion.div>
        {/* graphics design */}
      </motion.div>
    </section>
  );
};

export default Benefits;
