import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion } from "framer-motion";
import { SelectedPage } from "../../shared/type.js";

const childVariant = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1 },
};

const Benefit = ({ icon, title, description, descriptiontwo, descriptionthree, setSelectedPage }) => {
  return (
    <motion.div
      variants={childVariant}
      className="mt-3  shadow-2xl border-2 border-solid px-5 py-9 text-center rounded-md md:w-[600px] md:h-[450px] my-ease-out-animation"
    >
      <div className="mb-1 flex justify-center">
        <div className="rounded-full border-2 border-primary-100  p-2 ">{icon}</div>
      </div>
      <h4 className="font-bold text-xl">{title}</h4>

      <div className=" mt-3 flex flex-col justify-center items-start">
        <p className="m-1 p-2">{description}</p>
        <p className="m-1 p-2">{descriptiontwo}</p>
        <p className="m-1 p-2">{descriptionthree}</p>
      </div>

      <AnchorLink
        className="text-sm  text-primary-500 underline hover:text-secondary-500"
        onClick={() => setSelectedPage(SelectedPage.Whyus)} // Updated to match the value in SelectedPage
        href={`#${SelectedPage.ContactUs}`}>
      
        <p className="text-sm">Learn more</p>
      </AnchorLink>
    </motion.div>
  );
};

export default Benefit;
