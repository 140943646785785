import React from 'react';

function Class({ name, description, image }) {
  const overLayStyles = `p-5 absolute z-30 flex  whitespace-normal
    flex-col h-[380px] w-[450px] items-center justify-center
    bg-primary-300 text-center whitespace-normal 
    text-center text-primary-100 font-bold  opacity-0  transition duration-500 hover:opacity-90`;

  return (
    <li className="relative mx-5 inline-block h-[380px] w-[450px] ">
      <div className={overLayStyles}>
        <p className="text-2xl">{name}</p>
        <p className="mt-5">{description}</p>
      </div>
      <img src={image} className="w-full h-full" alt="image" />
    </li>
  );
}

export default Class;

