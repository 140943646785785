import React, { useState } from "react";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/20/solid";
import useMediaQuery from "../../hooks/useMediaQuery";
import Logo from "../../assets/looogo.png";
import Link from "./Link";
import { SelectedPage } from "../../shared/type";
import ActionButton from "../../shared/ActionButton";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navbar = ({ isTopOfPage, selectedPage, setSelectedPage }) => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const flexBetween = "flex items-center justify-evenly";
  const iSAboveMediumAScreen = useMediaQuery("(min-width:1060px)");
  const navBarBackground = isTopOfPage ? "" : "bg-primary-100 drop-shadow text-white";

  return (
    <nav>
      <div className={`${navBarBackground} ${flexBetween} fixed top-0 z-30 w-full py-6`}>
        <div className={`${flexBetween} mx-auto w-5/6`}>
          <div className={`${flexBetween} w-full gap-16`}>
            {/* leftside */}
            <AnchorLink onClick={() => setSelectedPage(SelectedPage.ContactUs)} href={`#${SelectedPage.Home}`}>
              <img alt="logo" src={Logo} />
            </AnchorLink>
            {/* rightside */}
            {iSAboveMediumAScreen ? (
              <div className={`${flexBetween} w-full `}>
                <div className={`${flexBetween} gap-8 text-lg font-black text-white`}>
                  <Link page="Home" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                  <Link page="Services" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                  <Link page="About Us" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                  <Link page="Why choose Us" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                </div>

                <div className={`${flexBetween} gap-8 `}>
                  <ActionButton setSelectedPage={setSelectedPage}>Contact us</ActionButton>
                </div>
                <div></div>
              </div>
            ) : (
              <button className="rounded-full bg-secondary-500 p-2" onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <Bars3Icon className="h-6 w-6 text-white" />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* mobile menu model */}
      {!iSAboveMediumAScreen && isMenuToggled && (
        <div className="fixed right-0 bottom-0 z-40 h-full w-[200px] bg-primary-100 ">
          <div className="flex justify-end p-6">
            <button onClick={() => setIsMenuToggled(!setIsMenuToggled)}>
              <XMarkIcon className="h-6 w-6 text-gray-400" />
            </button>
          </div>
          {/* menuitems */}
          <div className="ml-[33%] flex text-lg font-black text-white flex-col gap-8 ">
            <Link page="Home" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <Link page="Services" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <Link page="About Us" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <Link page="Why choose Us" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </div>

          <div></div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
